import '../../App.css';

function Contact() {
  return (
    <div className="contentContainer">
        <div className="contentHeader"> 
          CONTACT
        </div>
        <div className="contentBody"> 
          <ul>
            <li>Email : <a href="mailto:chenn.amy@gmail.com">chenn.amy@gmail.com</a></li>
            <li>Linkedin : <a href="https://www.linkedin.com/in/aalchen">aalchen</a></li>
            <li>Github : <a href="https://github.com/aalchen/">aalchen</a></li>
          </ul>
        </div>
    </div>
  );
}

export default Contact;
