import "../App.css";
import MenuItem from "./MenuItem";
import { Link } from "react-router-dom";
import Logo from "../images/logo.svg";

function MenuContainer() {
  return (
    <div className="menuContainer">
      <div className="logoContainer">
        <img
          style={{ marginTop: "20px", height: "50px" }}
          src={Logo}
          alt="Amy Chen"
        />
      </div>
      <div className="menuItemsContainer">
        <Link to="/home">
          <MenuItem type="HOME" />
        </Link>
        <Link to="/profile">
          <MenuItem type="PROFILE" />
        </Link>
        <Link to="/experience">
          <MenuItem type="EXPERIENCE" />
        </Link>
        <Link to="/projects">
          <MenuItem type="PROJECTS" />
        </Link>
        <Link to="/contact">
          <MenuItem type="CONTACT" />
        </Link>
      </div>
    </div>
  );
}

export default MenuContainer;
