import "../../App.css";

function Profile() {
  return (
    <div className="contentContainer">
      <div className="contentHeader">PROFILE</div>
      <div className="contentBody">
        I'm a new graduate from the UBC Bachelor of Computer Science program
        (Dec '23), currently working as an ASP.NET developer at Peloton
        Technologies. 👩‍💻
      </div>
      <div className="contentBody">
        My interests lie in full stack web development, with a growing interest
        in design, HCI, and UI/UX.
      </div>
      <div className="contentBody">
        I have a bit of an unconventional background - after graduating from
        business school, I worked full-time in Finance/Accounting for 1.5 years!
      </div>
      <div className="contentBody">
        There, I further confirmed my love for solving and identifying
        inefficiencies through automation and code, which drove me to further
        explore Computer Science as a profession.📚
      </div>
      <div className="contentBody">
        Since then, I've learned and grown significantly as a Software Developer
        co-op at :
        <ul>
          <li>RBC (8 months)</li>
          <li>Article (4 months)</li>
          <li>
            Peloton Technologies (4 months) - where I'm working full time now!
          </li>
        </ul>
      </div>
      <div className="contentBody">
        I've owned features, identified and fixed bugs, while applying my skills
        through codebase contributions in primarily ASP.NET, Java, Typescript,
        React, and Vue.
      </div>
      <div className="contentBody">
        I'm more excited than ever to continue to learn and grow in this
        everchanging field.
      </div>
      <hr />
      <div className="contentHeader">✨ Fun Facts</div>
      <div className="contentBody">
        <ul>
          <li>Based in beautiful Vancouver, Canada</li>
          <li>Previously lived in Victoria, Ottawa, and Mannheim 🇨🇦🇩🇪</li>
          <li>
            Can eavesdrop reasonably well in French, Mandarin, Cantonese, and
            Korean
          </li>
          <li>Interested in urban geography and development</li>
          <li>Passionate about continuous improvement and learning</li>
          <li>Loves travelling, grocery shopping, and Ru Paul's Drag Race</li>
          <li>
            Currently trying to learn how to sew 🪡, and bake sourdough bread 🍞
            <ul>
              <li>Message me if you have any tips!</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Profile;
