import '../App.css';
import Home from './content/Home';
import Profile from './content/Profile';
import Experience from './content/Experience';
import Contact from './content/Contact';
import Projects from './content/Projects';

function InfoContainer(props) {
    return (
      <div className="infoContainer">
          {props.type === "home" ? <Home /> : ""}
          {props.type === "profile" ? <Profile /> : ""}
          {props.type === "experience" ? <Experience /> : ""}
          {props.type === "contact" ? <Contact /> : ""}
          {props.type === "projects" ? <Projects /> : ""}
          {props.type === "landing" ? "Landing page!" : ""}
      </div>
    );
  }
  
  export default InfoContainer;
  