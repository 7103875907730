import "../../App.css";

function Home() {
  return (
    <div className="contentContainer">
      <div className="contentHeader">HOME</div>
      <div className="contentBody">
        My name is Amy - welcome to my website! 👋
      </div>
      <div className="contentBody">
        I'm currently :
        <ul>
          <li>a fresh new graduate from the UBC BCS program</li>
          <li>working full-time as an full stack ASP.NET developer</li>
          <li>based in Vancouver, Canada</li>
        </ul>
      </div>
      <div className="contentBody" style={{ textAlign: "center" }}>
        🌸🌸🌸
      </div>
    </div>
  );
}

export default Home;
