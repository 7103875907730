import "../../App.css";

function Experience() {
  return (
    <div className="contentContainer">
      <div className="contentHeader">WORK EXPERIENCE</div>
      <div className="contentBody">
        🌟 See full resume <a href={require("./Amy Chen CV.pdf")}>here</a>!
      </div>
      <div className="contentBody">
        <img
          src={require("../../images/peloton_logo.png")}
          alt="Peloton logo"
          className="companyLogo"
        />
        Peloton Technologies <br />
        Software Developer (Co-op -> Full-Time)
      </div>
      <div className="contentDescription">
        Sept 2023 - Dec 2023 -> Jan 2024 - Current
      </div>
      <div className="contentBody">
        <ul>
          <li style={{ fontWeight: "bold" }}>
            Tech: ASP.NET, Typescript, PHP{" "}
          </li>
          <li>COMING SOON...</li>
        </ul>
      </div>
      <div className="contentBody">
        <img
          src={require("../../images/article_logo.png")}
          alt="Article logo"
          className="companyLogo"
        />
        Article <br />
        Full Stack Software Engineer (Co-op)
      </div>
      <div className="contentDescription">May 2022 - Aug 2022</div>
      <div className="contentBody">
        <ul>
          <li style={{ fontWeight: "bold" }}>
            Tech: Java Spring, Vue, Kafka, SQL{" "}
          </li>
          <li>
            Investigated concerns from internal teams and solved bugs during
            unexpected production behaviour, auditing AWS Cloudwatch logs and
            production database to improve Java Spring backend
          </li>
          <li>
            Increased automated test coverage by designing JUnit and Mockito
            tests to validate backend logic
          </li>
          <li>
            Changed API endpoints and Kafka topics, and added visibility
            accordingly on Vue frontend
          </li>
          <li>
            Tested behaviour of endpoints using Postman, queried production SQL
            database to audit behaviour of data
          </li>
          <li>
            Created documentation regarding internal technology architecture,
            microservices, and M1-chip dev setup issues
          </li>
        </ul>
      </div>
      <div className="contentBody">
        <img
          src={require("../../images/rbc_logo.png")}
          alt="RBC logo"
          className="companyLogo"
        />
      </div>
      <div className="contentBody">
        Royal Bank of Canada <br />
        Software Developer (Co-op)
      </div>
      <div className="contentDescription">Sept 2021 - Apr 2022</div>
      <div className="contentBody">
        <ul>
          <li style={{ fontWeight: "bold" }}>
            Tech: React, Electron, Node, Java, Powershell, C#, SQLite{" "}
          </li>
          <li>
            Created a multi-Folder Cloud Backup feature with loading bar, and a
            Web Browser Cache deletion feature through Powershell scripting with
            a React frontend
          </li>
          <li>
            Implemented features and bug fixes on Windows (Java, C#,
            Powershell), and transferred functionality to a new web app port
            (React, Node, Electron, JS)
          </li>
          <li>
            Integrated an SQLite database into a Java desktop application to
            centralize collection of app usage data analytics
          </li>
          <li>
            Emphasized an overall need for reusable and maintainable code,
            consolidating repetitive CSS and hardcoded bilingual translations
          </li>
        </ul>
      </div>
      <div className="contentBody">
        <img
          src={require("../../images/hootsuite_logo.png")}
          alt="Hootsuite logo"
          className="companyLogo"
        />
      </div>
      <div className="contentBody">
        Hootsuite <br />
        Accounts Payable Specialist
      </div>
      <div className="contentDescription">Feb 2019 - Aug 2020</div>
      <div className="contentBody">
        <ul>
          <li>
            Automated the corporate credit card reconciliation process for 30
            cards, reducing task time significantly through GSheet scripting and
            formulas, and reduced risk of human error
          </li>
          <li>
            Setup a Netsuite script to send remittance emails to our vendors,
            strengthening vendor-customer relations and creating a more robust
            Accounts Payable function
          </li>
          <li>
            Customized macros in Zendesk to standardize communication,
            increasing processing efficiency
          </li>
          <li>
            Established an internal website and created process documentation,
            delivered training to management and peers
          </li>
        </ul>
      </div>
      <hr />
      <div className="contentHeader">VOLUNTEER EXPERIENCE</div>
      <div className="contentBody">
        <img
          src={require("../../images/bcssa_logo.png")}
          alt="BCSSA logo"
          className="companyLogo"
        />
      </div>
      <div className="contentBody">
        Bachelor of Computer Science Student Association <br />
        Treasurer
      </div>
      <div className="contentDescription">Jan 2021 - Dec 2021</div>
      <div className="contentBody">
        <ul>
          <li>
            Organized the 2021 virtual BCS Hackathon with over 60 participants,
            and secured over $7,000 in funding through grant and sponsorship
            funding{" "}
          </li>
          <li>
            Set up the Hackathon Notion site, finalized schedule, and managed
            all socials for the Hackathon event (Facebook, Devpost, mass emails
            through sendinblue)
          </li>
        </ul>
      </div>
      <div className="contentBody">
        <img
          src={require("../../images/cra_logo.png")}
          alt="Canada Revenue Agency logo"
          className="companyLogo"
        />
      </div>
      <div className="contentBody">
        Community Volunteer Income Tax Program (CRA) <br />
        Director of Marketing (UVic)
      </div>
      <div className="contentDescription">Jan 2018 - May 2018</div>
      <div className="contentBody">
        <ul>
          <li>
            Designed and executed a scheduled marketing and socials plan to
            increase engagement with the local community out of our clinics
            through the UVic Gustavson School of Business
          </li>
          <li>
            Tailored communication tools to ensure precision and clarity in
            wording, establishing materials to be used in future years,and
            reached out to all potential community organizations and partners
          </li>
          <li>
            Created print and digital advertising content from scratch in Adobe
            Illustrator
          </li>
          <li>
            Accurately prepared T1 tax e-Filing for eligible community members
          </li>
        </ul>
      </div>
      <div className="contentBody">
        <img
          src={require("../../images/redcross_logo.png")}
          alt="German Red Cross logo"
          className="companyLogo"
        />
      </div>
      <div className="contentBody">
        German Red Cross <br />
        Refugee Engagement
      </div>
      <div className="contentDescription">Oct 2017 - Dec 2017</div>
      <div className="contentBody">
        <ul>
          <li>
            Organized cultural activities in French to expose Guinean refugee
            camp residents to the language and customs of Germany
          </li>
          <li>
            Assisted in running the Christmas potluck at the Benjamin Franklin
            Village Refugee Camp, and running the childrens activity tables
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Experience;
