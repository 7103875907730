import '../App.css';

function MenuItem(props) {
    return (
      <div className="menuItem">
          {props.type}
      </div>
    );
  }
  
  export default MenuItem;
  