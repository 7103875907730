import "./App.css";
import MenuContainer from "./components/MenuContainer";
import InfoContainer from "./components/InfoContainer";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <MenuContainer />
        <Routes>
          <Route path="/home" element={<InfoContainer type={"home"} />} />
          <Route path="/profile" element={<InfoContainer type={"profile"} />} />
          <Route
            path="/experience"
            element={<InfoContainer type={"experience"} />}
          />
          <Route path="/contact" element={<InfoContainer type={"contact"} />} />
          <Route
            path="/projects"
            element={<InfoContainer type={"projects"} />}
          />
          <Route path="/" element={<InfoContainer type={"home"} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
