import "../../App.css";

function Projects() {
  return (
    <div className="contentContainer">
      <div className="contentHeader">PROJECTS</div>
      <div className="contentBody">
        <div className="projectsInsert">
          <div className="projectsName">Vibesphere</div>
          <div className="projectsImage">
            <img
              style={{ width: "300px", height: "175px" }}
              src={require("../../images/vibesphere.png")}
              alt="Screenshot of Project"
            />
          </div>
          <div className="projectsSummary">
            <ul>
              <li>
                Find songs similar to those you already like, vote for your
                favourites, make and share playlists, and see what others are
                searching!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contentBody">
        <a href="https://vibesphere.onrender.com/">Live</a>|
        <a href="https://github.com/KDhieb/cpsc-455-project">Github</a>
        <br />
        Tech : MongoDB, Express, React, Node, Spotify API, Machine Learning,
        Material-UI
      </div>
      <div className="learningsHeader">🧠 LEARNINGS</div>
      <div className="learningsText">
        <ul>
          <li>
            Understand the team's strengths and weaknesses, and leverage
            interests within each person
          </li>
          <li>
            Test regressively, stuff might randomly break... (and ideally have
            automated tests)
          </li>
          <li>Environment variables are your friend</li>
          <li>Machine learning algorithms are complicated</li>
          <li>
            MongoDB schemas are very versatile, quite easy to manipulate and
            query
          </li>
        </ul>
      </div>
      <hr />
      <div className="contentBody">
        <div className="projectsInsert">
          <div className="projectsName">UBC Course Explorer</div>
          <div className="projectsImage">
            <img
              style={{ width: "300px", height: "175px" }}
              src={require("../../images/insightubc.png")}
              alt="Screenshot of Project"
            />
          </div>
          <div className="projectsSummary">
            <ul>
              <li>
                Put in complex JSON queries to check against UBC Course and Room
                information
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contentBody">
        <a href="https://github.com/aalchen/campusexplorer">Github</a>
        <br />
        Tech : Typescript, Express, Svelte
      </div>
      <div className="learningsHeader">🧠 LEARNINGS</div>
      <div className="learningsText">
        <ul>
          <li>
            Knowing the parameters, expected inputs and outputs, and edge cases
            is critical
          </li>
          <li>Understand the problem very carefully before you proceed</li>
          <li>Make careful and meaningful changes when debugging</li>
        </ul>
      </div>
      <hr />
      <div className="contentBody">
        <div className="projectsInsert">
          <div className="projectsName">Video Game Awards Database</div>
          <div className="projectsImage">
            <img
              style={{ width: "300px", height: "175px" }}
              src={require("../../images/videogame_2.png")}
              alt="Screenshot of Project"
            />
          </div>
          <div className="projectsSummary">
            <ul>
              <li>
                Check database information about Video Game Awards and Awards
                Ceremonies
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contentBody">
        <a href="https://github.com/aalchen/gameshowdb">Github</a>
        <br />
        Tech : Java Swing, Mockito
      </div>
      <div className="learningsHeader">🧠 LEARNINGS</div>
      <div className="learningsText">
        <ul>
          <li>
            Have a good fundamental understanding of the data you're trying to
            represent (draw it out! ER diagrams!)
          </li>
          <li>Read the specifications of a project carefully</li>
          <li>Test, test, test!</li>
          <li>
            Protect your database from malicious users, paramaterize and prevent
            SQL injections
          </li>
        </ul>
      </div>
      <hr />
      <div className="contentBody">
        <div className="projectsInsert">
          <div className="projectsName">Inventory Tracker</div>
          <div className="projectsImage">
            <img
              style={{ width: "300px", height: "175px" }}
              src={require("../../images/minimize_project.png")}
              alt="Screenshot of Project"
            />
          </div>
          <div className="projectsSummary">
            <ul>
              <li>
                Inventory your personal belongings to make better use of them
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contentBody">
        <a href="https://github.com/aalchen/inventory-tracker">Github</a>
        <br />
        Tech : Java Swing, Mockito
      </div>
      <div className="learningsHeader">🧠 LEARNINGS</div>
      <div className="learningsText">
        <ul>
          <li>Automated tests and test-driven development are important</li>
          <li>General OOP principles and best practices</li>
          <li>
            Planning the scope of the project ahead of time makes a significant
            impact
          </li>
          <li>
            Implemented CRUD operations, and used JSON to persist data locally{" "}
          </li>
          <li>
            How to create a terminal application first to validate logic, before
            building the GUI
          </li>
        </ul>
      </div>
      <hr />
      <div className="contentBody">
        <div className="projectsInsert">
          <div className="projectsName">BCSSA Website</div>
          <div className="projectsImage">
            <img
              style={{ width: "300px", height: "175px" }}
              src={require("../../images/bcssa_project.png")}
              alt="Screenshot of Project"
            />
          </div>
          <div className="projectsSummary">
            <ul>
              <li>A sample website for my student association at UBC</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contentBody">
        <a href="https://github.com/aalchen/bcssa-website">Github </a> <br />
        Tech : MongoDB, Express, React, Node
      </div>
      <div className="learningsHeader">🧠 LEARNINGS</div>
      <div className="learningsText">
        <ul>
          <li>Successfully deployed a full-stack MERN project!</li>
          <li>CORS is tricky...</li>
          <li>
            It's hard to work with old NPM packages that are no longer supported
            and buggy
          </li>
        </ul>
      </div>
      <hr />
      <div className="contentBody">
        <div className="projectsInsert">
          <div className="projectsName">Walk Weather Check</div>
          <div className="projectsImage">
            <img
              style={{ width: "300px", height: "175px" }}
              src={require("../../images/walk_project.png")}
              alt="Screenshot of Project"
            />
          </div>
          <div className="projectsSummary">
            <ul>
              <li>
                Should you go on a walk, or stay inside? Checks your local
                weather!
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="contentBody">
        <a href="https://walkweathercheck.netlify.app/">Live</a>|
        <a href="https://github.com/aalchen/walkorstay">Github</a>
        <br />
        Tech : React, Tachyons, APIs
      </div>
      <div className="learningsHeader">🧠 LEARNINGS</div>
      <div className="learningsText">
        <ul>
          <li>
            Interesting trying to implement two different APIs (IP Address and
            Weather), especially when they rely on each other for conditional
            behaviour
          </li>
          <li>
            Learned how to use hooks, and passing props between components
            better
          </li>
          <li>Adblockers can be mean (UBlock Origin kills the API call)</li>
          <li>
            The difference between APIs that support http and https, the free
            OpenWeatherMap API doesn't support https (unfortunately){" "}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Projects;
